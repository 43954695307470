import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSingleOrderList } from "../../../redux/actions/OrderActions";

const Notification = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateToOrder = (res) => {
    if (res?.screen === "order") {
      dispatch(getSingleOrderList(res?.order_id));
      navigate(`/order/orderCustomerDetails/${res?.order_id}`);
    }
  };

  return (
    <ul
      style={{
        position: "absolute",
        top: "100%",
        right: 0,
        width: "300px",
        maxHeight: "300px",
        overflowY: "auto",
        listStyle: "none",
        backgroundColor: "#fff",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        margin: 0,
        padding: 0,
        zIndex: 1000,
        borderRadius: "4px",
      }}
    >
      <li
        style={{
          fontWeight: "bold",
          padding: "10px",
          borderBottom: "1px solid #ddd",
          backgroundColor: "#f8f9fa",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>Notifications</span>
        <span
          style={{
            backgroundColor: "#007bff",
            color: "#fff",
            borderRadius: "12px",
            padding: "2px 8px",
            fontSize: "12px",
          }}
        >
          {item?.count}
        </span>
      </li>

      {item?.data?.map((res, index) => (
        <li
          key={index}
          onClick={() => navigateToOrder(res)}
          style={{
            padding: "10px",
            cursor: "pointer",
            backgroundColor: "transparent",
            transition: "background-color 0.3s ease",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = "#f0f0f0")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = "transparent")
          }
        >
          <div>
            <h6 style={{ margin: "0 0 5px 0", fontSize: "16px" }}>
              {res?.name}
            </h6>
            <p style={{ margin: 0, fontSize: "14px", color: "#555" }}>
              {res?.description}
            </p>
          </div>
        </li>
      ))}

      {item?.data?.length === 0 && (
        <li
          style={{
            padding: "10px",
            textAlign: "center",
            color: "#777",
          }}
        >
          No new notifications
        </li>
      )}
    </ul>
  );
};

export default Notification;
