import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, mixed } from "yup"
import { useForm } from "react-hook-form";
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import SelectInput from '../common/SelectInput';
import { EditorderStatus, getOrderStatus } from '../../redux/actions/OrderActions';
const OrderStatusModal = ({ openModal, onCloseModal, data }) => {
	const dispatch = useDispatch()
	const { orderstatusList } = useSelector(state => state.orders)
	const [options, setOptions] = useState([])
	// const [paymentStatus, setPaymentStatus] = useState([{ value: 'pending', label: 'Pending' }, { value: 'paid', label: 'Paid' }])
	const [orderStatus, setOrderStatus] = useState([{ value: 'accepted', label: 'Accepted' }, { value: 'completed', label: 'Completed' }, { value: 'Cancelled', label: 'Cancelled' }])

	const [loading, setLoading] = useState(false);

	const schema = object().shape({

	})

	console.log(orderstatusList,"list")


	useEffect(() => {
		
		if (orderstatusList) {
			let statuss = orderstatusList.map(st => {
				return {
					label: st.name,
					value: st.name
				}
			})

			setOptions(statuss)
		}
	}, [orderstatusList])





	const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue } = useForm({
		resolver: yupResolver(schema),
		//   defaultValues: {
		//     status: data?.orderStatus
		// }

	})



	useEffect(() => {
		if (data) {
			// const paymentfind =  paymentStatus.find((res)=>res?.value === data?.paymentStatus);
			const statusFind =  orderStatus.find((res)=>res?.value === data?.orderStatus)
			setValue("Status", statusFind);
			// setValue("paymentStatus", paymentfind);
		}

	}, [data])
	

	useEffect(() => {
		dispatch(getOrderStatus())
	}, [])


	const SubmitForm = (datas) => {
		setLoading(true)
		// console.log({ datas })

		let val = {
			orderID: data?._id,
			orderStatus: datas.Status === "" ? data?.orderStatus : datas.Status?.value,
			// paymentStatus: datas?.paymentStatus?.value
		}


		dispatch(EditorderStatus(val)).finally(() => setLoading(false));
		onCloseModal()

	}

	return (
		<Modal isOpen={ openModal } toggle={ onCloseModal }>
			<ModalHeader toggle={ onCloseModal }>
				<h5
					className="modal-title f-w-600"
					id="exampleModalLabel2"
				>
					Edit Status
				</h5>
			</ModalHeader>
			<ModalBody>
				<Form>
					{/* <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <label>Current Status</label>
            <h1 style={{ fontSize: "12px" }}>{data?.orderStatus}</h1>
          </div> */}

					<SelectInput fieldLabel='Status'
						control={ control }
						error={ errors.Status }
						fieldName='Status'
						options={ orderStatus }
					/>

					{/* <SelectInput fieldLabel='Payment Status	'
						control={ control }
						error={ errors.paymentStatus }
						fieldName='paymentStatus'
						options={ paymentStatus }
					/> */}
				</Form>
			</ModalBody>
			<ModalFooter>
				<Button
					type="button"
					color="primary"
					disabled={loading ? true : false}
					onClick={ handleSubmit(SubmitForm) }
				>
					{loading ? "Saving..." : "Save"}
				</Button>

				{/* <Button
          type="button"
          color="secondary"
          onClick={onCloseModal}
        >
          Close ̰
        </Button> */}
			</ModalFooter>
		</Modal>
	)
}

export default OrderStatusModal