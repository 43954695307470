import React, { useRef } from "react";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { MAPS } from "../../config/constant";
import { Label } from "reactstrap";
import { Controller } from "react-hook-form";

const libraries = ["places"];

const EditMap = ({ setValue, control }) => {
  const autocompleteRef = useRef(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: MAPS,
    libraries,
  });

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();

    if (place && place.geometry) {
      setValue("address", {
        address: place.formatted_address,
      });
    }
  };

  if (isLoaded) {
    return (
      <div>
        <Label style={{ marginBottom: 6 }}>Store Location</Label>
        <Controller
          name={"address"}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <Autocomplete
                onLoad={(ref) => (autocompleteRef.current = ref)}
                onPlaceChanged={onPlaceChanged}
              >
                <input
                  {...field}
                  value={field?.value?.address}
                  type="text"
                  placeholder="Enter a location"
                  style={{
                    width: "100%",
                    padding: "10px",
                    fontSize: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                    transition: "border-color 0.2s, box-shadow 0.2s",
                  }}
                />
              </Autocomplete>

              {error && (
                <p
                  role="alert"
                  style={{
                    color: "red",
                    display: "flex",
                    flexDirection: "start",
                    paddingLeft: "10px",
                    fontSize: "12px",
                  }}
                >
                  {error?.message}
                </p>
              )}
            </>
          )}
        />
        <br />
        {/* You can add a map for location visualization if needed */}
        {/* <GoogleMap
          mapContainerStyle={{ height: "400px", width: "100%" }}
          center={currentPosition}
          zoom={14}
        /> */}
      </div>
    );
  }

  return null;
};

export default EditMap;
