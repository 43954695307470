import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Toast,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInput from "../common/CustomInput";
import customAxios from "../../customAxios";
import { toast } from "react-toastify";
import SelectInput from "../common/SelectInput";
import ImagesInput from "../common/ImagesInput";

const AddRider = ({ openModal, onCloseModal, title, data }) => {
  console.log(data,"dataaa")
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState();
  const [stores, setStores] = useState([]);
  const [image, setImage] = useState("");
  const [selectedValue, setSelectedValue] = useState();

  const schema = yup.object({
    name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Name must contain only alphabets")
      .max(40, "Name must be at most 40 characters")
      .required("Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    store: yup
      .object()
      .required("Store is required")
      .typeError("Store is required"),
    mobile: yup
      .string()
      .required("Phone number is required")
      .test("isMobile", "Invalid phone number format", (value) => {
        const mobileRegex = /^[+]?[0-9]{8,16}$/; // Adjust regex for your specific needs
        return mobileRegex.test(value);
      }),
    password: yup.string().when("isEdit", {
      is: data?._id,
      then: yup
        .string()
        .required("Password is required")
        .test("lowercase", "Contain at least one lowercase letter", (value) =>
          /[a-z]/.test(value)
        )
        .test("number", "Contain at least one number", (value) =>
          /[0-9]/.test(value)
        )
        .test("uppercase", "Contain at least one uppercase letter", (value) =>
          /[A-Z]/.test(value)
        )
        .test("special", "Contain at least one special character", (value) =>
          /[!@#$%^&=*()_+{}\[\]:;<>,.?~\\|]/.test(value)
        )
        .min(8, "Password at least 8 character long"),
      otherwise: yup.string().nullable(),
    }),
    confPasswd: yup.string().when("isEditt", {
      is: data?._id,
      then: yup
        .string()
        .oneOf([yup.ref("password"), null], "Password must match")
        .required("Confirm Password required"),
      otherwise: yup.string().nullable(),
    }),
    // role: yup.object().nullable()
  });

  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      mobile: "",
      role,
      store: {},
      password: "",
    },
  });

  const clearclose = () => {
    onCloseModal();
    reset();
  };

  const getStoreList = () => {
    customAxios
      .get("admin/store")
      .then((datas) => {
        setStores(
          datas.data?.data?.map(({ _id, storename }) => ({
            value: _id,
            label: storename,
          }))
        );
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    getStoreList();
    setImage(null);

    if (data) {
      setRole({
        value: data?.role,
        label: data?.role === "rider" ? "Rider " : "Store manager",
      });

      setSelectedValue({
        value: data?.store?.id,
        label: data?.store?.name || data?.store?.storename,
      });

      reset({
        name: data?.name,
        email: data?.email,
        mobile: data?.mobile,
        store: {
          value: data?.store?.id,
          label: data?.store?.name || data?.store?.storename,
        },
      });
    } else {
      setRole({ value: "rider", label: "Rider " });
      setValue("store", null);
      setSelectedValue(null);

      reset({
        name: "",
        email: "",
        mobile: "",
        password: "",
        confPasswd: "",
      });
    }
  }, [data, openModal]);

  const onImgLoad = (event) => {

    setImage(image);

    // if (img.offsetWidth === 256 && img.offsetHeight === 256) {
    //   setImage(image);
    // } else {
    //   toast.error("Image size must be 256x256");
    //   setImage(null);
    // }
  };

  const createRider = async (datas) => {
    try {
      setLoading(true);
      let response;

      const formData = new FormData();

      if (data) {
        formData.append("id", data?._id);
        data?.name !== datas?.name && formData.append("name", datas?.name);
        data?.email !== datas?.email && formData.append("email", datas?.email);
        data?.mobile !== datas?.mobile &&
          formData.append("mobile", datas?.mobile);
        datas?.password && formData.append("password", datas?.password);
        datas?.confPasswd &&
          formData.append("password_confirmation", datas?.confPasswd);
        role?.value && formData.append("role", role?.value);
        data?.store?.id !== datas?.store?.value &&
          formData.append(
            "store",
            JSON.stringify({
              id: datas?.store?.value,
              storename: datas?.store?.label,
            })
          );
      } else {
        formData.append("name", datas?.name);
        formData.append("email", datas?.email);
        formData.append("mobile", datas?.mobile);
        formData.append("password", datas?.password);
        formData.append("password_confirmation", datas?.confPasswd);
        formData.append("role", role?.value);
        formData.append(
          "store",
          JSON.stringify({
            id: datas?.store?.value,
            storename: datas?.store?.label,
          })
        );
      }

      if (image) {
        // formData.append(`image[${i}]`, img.file);
        formData.append("image", image);
      }

      response = await customAxios.post(
        data?._id ? "admin/update-user" : `admin/create-manager`,
        formData
      );

      toast.success(response?.data?.message);
      reset({
        name: "",
        email: "",
        mobile: "",
        password: "",
        store: "",
      });
      onCloseModal();
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={openModal} toggle={clearclose} size="md">
      <ModalHeader toggle={clearclose}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          {data ? "Edit " + role?.label : "New " + role?.label}
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Col>
            <CustomInput
              fieldLabel="Name :"
              control={control}
              error={errors.name}
              fieldName="name"
            />
          </Col>

          <Row>
            <Col>
              <SelectInput
                fieldLabel="Role	"
                selectvalue={role}
                disabled={data?._id}
                control={control}
                error={errors.paymentStatus}
                fieldName="role"
                onChangeValue={(item) => {
                  setRole(item);
                }}
                options={[
                  {
                    value: "rider",
                    label: "Rider",
                  },
                  { value: "manager", label: "Store manager" },
                ]}
              />
            </Col>

            <Col>
              <SelectInput
                fieldLabel="Store	"
                selectvalue={selectedValue}
                onChangeValue={(item) => {
                  setSelectedValue(item);
                  setValue("store", item);
                  setError("store", { type: "custom", message: null });
                }}
                control={control}
                error={errors.store}
                fieldName="store"
                options={stores}
              />
            </Col>
          </Row>

          <Col>
            <CustomInput
              fieldLabel="Email :"
              control={control}
              error={errors.email}
              fieldName="email"
            />
          </Col>

          <Col>
            <CustomInput
              fieldLabel="Mobile:"
              max={10}
              control={control}
              error={errors.mobile}
              fieldName="mobile"
              disabled={data?._id}
              code
            />
          </Col>

          <Col>
            <CustomInput
              fieldLabel="Password:"
              control={control}
              error={errors.password}
              fieldName="password"
              type={"password"}
              eye
            />
          </Col>

          <Col>
            <CustomInput
              fieldLabel="Confirm Password:"
              control={control}
              error={errors.confPasswd}
              fieldName="confPasswd"
              type={"password"}
              eye
            />
          </Col>

          <Col>
            <ImagesInput
              fieldLabel="Image (optional)"
              control={control}
              error={errors.image}
              fieldName="image"
              type="file"
              onChangeValue={(file) => {
                setImage(file);
              }}
            />

            {image ? (
              <div>
                <img
                  onLoad={onImgLoad}
                  src={URL.createObjectURL(image)}
                  width={100}
                  height={100}
                  style={{ borderRadius: 5 }}
                />
              </div>
            ) : data?.image ? (
              <div>
                <img
                  src={
                    `https://bitesmateapi.diginestsolutions.in/public/` +
                    data?.image
                  }
                  style={{ width: "80px", height: "80px" }}
                ></img>
              </div>
            ) : null}
          </Col>

          {/* <span style={{ color: "red" }}>Image resolution should be 768px * 1000px</span> */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={loading}
          type="button"
          color="primary"
          onClick={handleSubmit(createRider)}
        >
          {loading ? "Saving..." : !data ? "Save" : "Update"}
        </Button>
        <Button
          type="button"
          color="secondary"
          onClick={() => {
            onCloseModal();
            reset();
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddRider;
