
import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import DataTables from "../common/DataTables";
import customAxios from "../../customAxios";
import { toast } from "react-toastify";
import Switch from "../common/switch/Switch";
import { useDispatch, useSelector } from "react-redux";
import { DeletePostCode, GetPostCodes, GetUsermanangementList, UpdateUserManagementStatus } from "../../redux/actions/SettingActions";
import moment from "moment";
import { RESET_ERROR } from "../../redux/constants/userConstants";
import SearchHeader from "../common/header_components/searchHeader";
import AddPostCode from "./AddPostCode";

const PostCode = () => {


    const [open, setOpen] = useState(false)
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false); // Add loading state
    const [filterText, setFilterText] = useState('');
    const [originalData, setOriginalData] = useState([]);

    const customId = "delUser";
    const dispatch = useDispatch()

    const { post_code_data,
        delete_post_code, post_code_del_err } = useSelector(state => state?.settings)


    useEffect(() => {
        fetchPostCodes();
    }, []);

    useEffect(() => {
        if (post_code_data) {
            setData(post_code_data)
            setOriginalData(post_code_data);
        }
    }, [post_code_data])

    const fetchPostCodes = async () => {
        setLoading(true); // Start loading
        await dispatch(GetPostCodes());
        setLoading(false); // Stop loading
    };

    useEffect(() => {
        if (delete_post_code) {
            toast.success('Deleted Successfully')
            dispatch({
                type: RESET_ERROR
            })
            fetchPostCodes();
        } else if (post_code_del_err) {
            toast.error(`${post_code_del_err.message}`);
            dispatch({
                type: RESET_ERROR,
            });
        }
        dispatch(GetPostCodes())
    }, [delete_post_code, post_code_del_err])

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you wish to delete this item?")) {
            dispatch(DeletePostCode({
                "postcodes": [
                    id
                ]
            }))


            //getRidersList()
        }
    }

    const handleEdit = (data) => {
        setData(data)
        setOpen(true)
    }


    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };



    const handleToggleStatus = (storeId, currentStatus) => {
        const updatedData = {
            id: storeId,
            status: currentStatus === "active" ? "inactive" : "active"
        };

        dispatch(UpdateUserManagementStatus(updatedData))
            .then(() => {
                toast.success(`Status updated successfully`);
                dispatch(GetUsermanangementList())
            })
            .catch((error) => {
                toast.error(`Failed to update status: ${error.message}`);
            });
    };

    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Name")}</b>,
            header: <b>{Capitalize("Name")}</b>,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    <span onClick={() => handleDelete(row._id)} >

                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                </>
            )
        }
    ];


    const closeModal = () => {
        setOpen(false)
        // fetchPostCodes()
        // setData(null)
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
                setData(originalData);
            }
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                <SearchHeader onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
            </div>
        );
    }, [filterText, originalData]);

    useEffect(() => {
        if (filterText) {
            const filteredData = originalData.filter(sto =>
                sto?.name?.toLowerCase().includes(filterText.toLowerCase())
            );
            setData(filteredData);
        } else {
            setData(originalData); // Reset to original data if no filter text
        }
    }, [filterText, originalData]);



    return (
        <Fragment>
            <Breadcrumb title="Post code" parent="Users" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Post code</h5>
                            </CardHeader>
                            <CardBody>
                                <Button style={{ float: "right", marginBottom: 20 }} onClick={() => setOpen(true)}>Add</Button>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    {loading ? (
                                        <Spinner />
                                    ) : (
                                        originalData && originalData?.length > 0 ? (
                                            <DataTables
                                                columns={columns}
                                                row={data}
                                                pagination={true}
                                                noRowsPerPage={10}
                                                subHeader
                                                subHeaderComponent={subHeaderComponentMemo}
                                            />
                                        ) : (
                                            <p>No data available.</p>
                                        )
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <AddPostCode openModal={open} onCloseModal={closeModal} />
        </Fragment>
    )
}

export default PostCode