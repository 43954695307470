import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { filterOrder, getOrderList, getOrderStatus } from "../../redux/actions/OrderActions";
import { useNavigate } from "react-router-dom";
import OrderStatusModal from "./OrderStatusModal";
import { RESET_ERROR } from "../../redux/constants/userConstants";
import DataTable from "react-data-table-component";
import SearchHeader from "../common/header_components/searchHeader";
import CustomInput from "../common/CustomInputPast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CSVLink } from "react-csv"; // Import CSVLink
import moment from 'moment-timezone';
import customAxios from "../../customAxios";
import Billformat from "./Billformat";
// import AssignRiderModal from "./AssignRider";

// getorderlist
const OrderDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { orderList, orderupdated, error } = useSelector(
    (state) => state.orders
  );
  const { loading } = useSelector((state) => state.auth);

  const [datas, setDatas] = useState([]);

  console.log(datas, "SDsd")

  const [open, setOpen] = useState(false);
  const [billopen, setBillOpen] = useState(false);
  // const [assign, setAssign] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [activeData, setActiveData] = useState(null);
  // const [orderId, setOrderId] = useState('');
  // const [store, setStore] = useState('');
  // const [rider, setRider] = useState(null);

  // const [sortField, setSortField] = useState("");
  // const [sortDirection, setSortDirection] = useState("");

  // Define Capitalize once
  const Capitalize = (str) => {
    console.log(str, "str");
    if (typeof str !== 'string' || !str) return ''; // Handle invalid values
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    dispatch(getOrderStatus());
  }, [dispatch]);

  // Update `datas` when `orderList` changes and sort it descendingly by `order_id`
  useEffect(() => {
    if (orderList) {
      const sortedOrders = [...orderList].sort((a, b) => {
        const orderA = parseInt(a.order_id, 10) || 0;
        const orderB = parseInt(b.order_id, 10) || 0;
        return orderB - orderA; // Descending order
      });
      setDatas(sortedOrders);
    } else {
      setDatas([]);
    }
  }, [orderList]);

  // Navigation functions
  const Navigatedetailpage = (id) => {
    navigate(`/order/orderCustomerDetails/${id}`);
  };
  const handleEdit = (data) => {
    setActiveData(data);
    setOpen(true);
  };

  const handleBill = () => {
    setBillOpen(true);
  };

  // const handleAssign = (data) => {
  //   setStore(data?.store || '');
  //   setOrderId(data?._id || '');
  //   setRider(data?.rider || null);
  // };

  // Open Assign Modal when orderId is set
  // useEffect(() => {
  //   if (orderId) {
  //     setAssign(true);
  //   }
  // }, [rider, orderId]);


  const handleDownload = async (row) => {

    try {
      const response = await customAxios.get(`admin/printorder/${row?._id}`);
      if (response?.data?.success) {
        const link = document.createElement('a');
        link.href = response?.data?.data;
        link.setAttribute('download', 'Order_No556.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        toast.error("Failed to download order")
      }
    } catch (error) {
      //console.error('Error downloading invoice:', error);
      toast.error("An error occurred while downloading the invoice")
    }
  };

  // Filtering logic
  useEffect(() => {
    if (filterText) {
      const filteredDatas = (orderList || []).filter((ord) =>
        ord?.customer?.name
          ?.toLowerCase()
          .includes(filterText.toLowerCase()) ||
        ord?.shippingAddress?.mobile
          ?.toString()
          // ?.toLowerCase()
          .includes(filterText) ||
        `#ORD${ord?.order_id}`
          ?.toLowerCase()
          ?.includes(filterText?.toLowerCase()) ||
        ord?.orderStatus?.toLowerCase()?.includes(filterText.toLowerCase()) ||
        `${moment(ord?.orderDate).format("DD-MM-YYYY")}`
          ?.toString()
          .toLowerCase()
          .includes(filterText?.toLowerCase()) ||
        ord?.paymentStatus
          ?.toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${moment(ord?.slot_date).format("DD-MM-YYYY")}`
          ?.toString()
          .toLowerCase()
          .includes(filterText?.toLowerCase()) ||
        ord?.delivery_slot?.fromTime
          ?.toLowerCase()
          .includes(filterText?.toLowerCase())
      );

      setDatas(filteredDatas);
    } else {
      if (orderList) {
        const sortedOrders = [...orderList].sort((a, b) => {
          const orderA = parseInt(a.order_id, 10) || 0;
          const orderB = parseInt(b.order_id, 10) || 0;
          return orderB - orderA; // Descending order
        });
        setDatas(sortedOrders);
      } else {
        setDatas([]);
      }
    }
  }, [filterText, orderList]);

  // Form validation schema
  const schema = yup.object({
    fromDate: yup.string().required("From date required"),
    toDate: yup.string().required("To date required"),
  });

  // Form handling
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  // CSV headers
  // const csvHeaders = [
  //   { label: "Order Id", key: "order_id" },
  //   { label: "Order Date", key: "orderDate" },
  //   { label: "Store", key: "storeName" },
  //   { label: "Customer Name", key: "customerName" },
  //   { label: "Address", key: "address" },
  //   { label: "Mobile No", key: "mobile" },
  //   { label: "Post Code", key: "postcode" },
  //   { label: "Payment Type", key: "paymentType" },
  //   { label: "Payment Status", key: "paymentStatus" },
  //   { label: "Total", key: "total" },
  //   { label: "Slot Date", key: "slot_date" },
  //   { label: "Slot FromTime", key: "fromTime" },
  //   { label: "Slot ToTime", key: "toTime" },
  //   { label: "Order Status", key: "orderStatus" },
  // ];

  const csvHeaders = [
    { label: "Order Id", key: "order_id" },
    { label: "Address Line 1", key: "address" },
    { label: "Address Line 2", key: "addresstwo" },
    { label: "City", key: "cityName" },
    { label: "Post Code", key: "postcode" },
    { label: "Extra Info (Optional)", key: "comments" },
  ];

  // Prepare CSV data based on current datas state
  const csvData = React.useMemo(() => {
    if (!Array.isArray(datas)) return []; // Ensure datas is an array

    return datas.map((row) => ({
      order_id: row.order_id ? `#ORD${row.order_id}` : "-",
      address: row?.shippingAddress?.doorNumber + ", " + row?.shippingAddress?.streetName + ", " + row?.shippingAddress?.cityName + ", " + row?.shippingAddress?.country + ", " + row?.shippingAddress?.postcode || "-",
      addresstwo: row.shippingAddress?.street_name ? row.shippingAddress?.street_name : "-",
      cityName: row.shippingAddress?.cityName,
      postcode: row.postcode || "-",
      comments: row.shippingAddress?.comments ? row.shippingAddress?.comments : "-"
    }));
  }, [datas]);

  // SubHeader component with filters and CSV export
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
        if (orderList) {
          const sortedOrders = [...orderList].sort((a, b) => {
            const orderA = parseInt(a.order_id, 10) || 0;
            const orderB = parseInt(b.order_id, 10) || 0;
            return orderB - orderA; // Descending order
          });
          setDatas(sortedOrders);
        } else {
          setDatas([]);
        }
      }
    };

    const filterOrders = (data) => {
      dispatch(filterOrder(data));
    };

    const resetFun = () => {
      window.location.reload();
      dispatch(getOrderList(1));
    };

    return (
      <div style={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
        <Row style={{ marginRight: 50 }}>
          <Col>
            <CustomInput
              fieldLabel="From Date (Order)"
              control={control}
              error={errors.fromDate}
              fieldName="fromDate"
              type={"date"}
            />
          </Col>
          <Col>
            <CustomInput
              fieldLabel="To Date (Order)"
              control={control}
              error={errors.toDate}
              fieldName="toDate"
              type={"date"}
            />
          </Col>
          <Col style={{ paddingTop: 35 }}>
            <Button
              type="button"
              color="primary"
              onClick={handleSubmit(filterOrders)}
            >
              Submit
            </Button>
          </Col>
          <Col style={{ paddingTop: 35 }}>
            <Button type="button" color="primary" onClick={resetFun}>
              Reset
            </Button>
          </Col>
        </Row>
        <div style={{ display: "flex", alignItems: "start", gap: 10 }}>
          <SearchHeader
            onFilter={(e) => setFilterText(e.target.value)}
            onClear={handleClear}
            filterText={filterText}
          />
          {/* Export CSV Button */}
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={`order-data-${moment().format("YYYY-MM-DD")}.csv`}
            className="btn btn-success"
            target="_blank"
          >
            Export CSV
          </CSVLink>
          <Button type="button" color="secondary" onClick={handleBill}>
            Print Bill
          </Button>
        </div>
      </div>
    );
  }, [filterText, control, errors, orderList, dispatch, handleSubmit, csvData, csvHeaders]);

  var columns = [
    {
      name: <b>Actions</b>,
      id: "actions",
      cell: (row) => (
        <div>
          <span onClick={() => Navigatedetailpage(row?._id)}>
            <i
              className="fa fa-eye"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "#e4566e",
                cursor: "pointer",
              }}
            ></i>
          </span>

          <span onClick={() => handleEdit(row)}>
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "rgb(40, 167, 69)",
                cursor: "pointer",
              }}
            ></i>
          </span>
          {row?.paymentStatus?.toLowerCase() === "succeeded" && (
            <span onClick={() => handleDownload(row)}>
              <div
                style={{
                  background: "#000",
                  padding: 5,
                  borderRadius: 5,
                  cursor: "pointer",
                }}
              >
                <p style={{ color: "#fff", fontSize: 12, textAlign: "center" }}>
                  INVOICE
                </p>
              </div>
            </span>
          )}
        </div>
      ),
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("OrderId")}</b>,
      header: <b>{Capitalize("Order Id")}</b>,
      id: "order_id",
      selector: (row) => parseInt(row.order_id, 10) || 0, // Numeric value for sorting
      sortable: true,
      cell: (row) => (row?.order_id ? `#ORD${row.order_id}` : "-"), // Formatted display
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Order Date")}</b>,
      header: <b>{Capitalize("Order Date")}</b>,
      selector: (row) => row?.created_at ? new Date(row.created_at) : new Date(0), // Raw Date object for sorting
      sortable: true,
      cell: (row) =>
        row?.created_at
          ? `${moment.tz(row.created_at, "Europe/London").format("DD-MM-YYYY HH:mm")}`
          : "-", // Formatted display
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Store")}</b>,
      header: <b>{Capitalize("Store")}</b>,
      selector: (row) => row?.storeName || "-", // Raw value for sorting
      sortable: true,
      cell: (row) => row?.storeName || "-", // Formatted display
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Customer Name")}</b>,
      header: <b>{Capitalize("Customer Name")}</b>,
      selector: (row) => row?.customer?.name || "-", // Raw value for sorting
      sortable: true,
      cell: (row) => row?.customer?.name || "-", // Formatted display
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Mobile No (+44)")}</b>,
      header: <b>{Capitalize("Mobile No (+44)")}</b>,
      selector: (row) => row?.shippingAddress?.mobile?.toString() || "-", // Raw value for sorting
      sortable: true,
      cell: (row) => row?.shippingAddress?.mobile || "-", // Formatted display
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Post Code")}</b>,
      header: <b>{Capitalize("Post Code")}</b>,
      selector: (row) => row?.postcode || "-", // Raw value for sorting
      sortable: true,
      cell: (row) => row?.postcode || "-", // Formatted display
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Payment Type")}</b>,
      header: <b>{Capitalize("Payment Type")}</b>,
      selector: (row) => row?.paymentType || "-", // Raw value for sorting
      sortable: true,
      cell: (row) => row?.paymentType || "-", // Formatted display
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Payment Status")}</b>,
      header: <b>{Capitalize("Payment Status")}</b>,
      selector: (row) =>
        row?.paymentStatus === "captured" ? "paid" : row?.paymentStatus || "-", // Raw value for sorting
      sortable: true,
      cell: (row) =>
        row?.paymentStatus === "captured" ? "paid" : row?.paymentStatus || "-", // Formatted display
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Total")}</b>,
      header: <b>{Capitalize("Total")}</b>,
      selector: (row) => parseFloat(row?.total) || 0, // Raw numeric value for sorting
      sortable: true,
      cell: (row) => `£ ${parseFloat(row.total).toFixed(2)}` || "-", // Formatted display
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Slot Date")}</b>,
      header: <b>{Capitalize("Slot Date")}</b>,
      selector: (row) =>
        row.slot_date
          ? moment(row.slot_date, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY")
          : "-", // Raw date string for sorting
      sortable: true,
      cell: (row) =>
        row.slot_date
          ? moment(row.slot_date, "YYYY-MM-DD HH:mm").format("DD-MM-YYYY")
          : "-", // Formatted display
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Slot FromTime")}</b>,
      header: <b>{Capitalize("Slot FromTime")}</b>,
      selector: (row) => row?.delivery_slot?.fromTime || "-", // Raw value for sorting
      sortable: true,
      cell: (row) => row?.delivery_slot?.fromTime || "-", // Formatted display
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Slot ToTime")}</b>,
      header: <b>{Capitalize("Slot ToTime")}</b>,
      selector: (row) => row?.delivery_slot?.toTime || "-", // Raw value for sorting
      sortable: true,
      cell: (row) => row?.delivery_slot?.toTime || "-", // Formatted display
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Order Status")}</b>,
      header: <b>{Capitalize("Order Status")}</b>,
      selector: (row) =>
        row?.orderStatus === "paid" ? "created" : row?.orderStatus || "-", // Raw value for sorting
      sortable: true,
      cell: (row) =>
        row?.orderStatus === "paid" ? "created" : row?.orderStatus || "-", // Formatted display
      style: {
        textAlign: "center",
      },
    },
  ];

  // Handle sorting to maintain sorted data in `datas`
  const handleSort = (column, sortDirection) => {
    const sortedDatas = [...datas].sort((a, b) => {
      let aValue = column.selector(a);
      let bValue = column.selector(b);

      // Handle different data types
      if (typeof aValue === "string") {
        aValue = aValue?.toLowerCase();
        bValue = bValue?.toLowerCase();
      } else if (aValue instanceof Date && bValue instanceof Date) {
        aValue = aValue?.getTime();
        bValue = bValue?.getTime();
      }

      if (aValue > bValue) {
        return sortDirection === "asc" ? 1 : -1;
      }
      if (aValue < bValue) {
        return sortDirection === "asc" ? -1 : 1;
      }
      return 0;
    });

    setDatas(sortedDatas);
  };

  // Handle page change (pagination)
  const handlePageChange = (page) => {
    dispatch(getOrderList(page));
  };

  // Pagination options
  const paginationComponentOptions = {
    noRowsPerPage: true,
  };

  // Toast notifications for updates and errors
  useEffect(() => {
    if (orderupdated) {
      toast.success(`Order Updated Successfully`);
      dispatch({
        type: RESET_ERROR,
      });
    }
  }, [orderupdated, dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(`${error.message}`);
      dispatch({
        type: RESET_ERROR,
      });
    }
  }, [error, dispatch]);

  // Fetch initial order list
  useEffect(() => {
    dispatch(getOrderList(1));
  }, [dispatch]);

  return (
    <Fragment>
      <Breadcrumb title="Order List" parent="Order" />
      {/* Container-fluid starts */}
      <Container fluid="true">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Order Details</h5>
              </CardHeader>
              <CardBody>
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical">
                  <Fragment>
                    <DataTable
                      pagination
                      title=""
                      columns={columns}
                      progressPending={loading}
                      progressComponent={
                        <Spinner animation="grow" variant="info" />
                      }
                      data={datas}
                      fixedHeader
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      onSort={handleSort}
                      sortServer={false} // Client-side sorting
                      onChangePage={handlePageChange}
                      paginationComponentOptions={paginationComponentOptions}
                      defaultSortFieldId={2} // Optional: Set default sort field
                    // Remove sortFunction since it's handled by onSort
                    />
                  </Fragment>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modals */}
      {/* <AssignRiderModal
        openModal={assign}
        onCloseModal={() => {
          setAssign(false);
        }}
        data={activeData}
        order_id={orderId}
        rider={rider}
        store={store}
        assign={assign}
      /> */}

      <Billformat
        openModal={billopen}
        onCloseModal={() => {
          setBillOpen(false)
        }}
        title={'Download Bill'}
      />

      <OrderStatusModal
        openModal={open}
        onCloseModal={() => {
          setOpen(false);
        }}
        data={activeData}
      />
    </Fragment>
  );
};

export default OrderDetails;
