import React from "react";
import { useSelector } from "react-redux";
import man from "../../../assets/images/dashboard/man.png";

const UserPanel = ({user}) => {

	const { profilelist } = useSelector(state => state.settings)
	return (
		<div>
			<div className="sidebar-user text-center">
				<div>
					<img
						className="img-60 rounded-circle lazyloaded blur-up"
						src={`${profilelist?.image}${profilelist?.logo}`}
						alt="#"
					/>
				</div>
				<h6 className="mt-3 f-14">{user?.name}</h6>
				<p>{user?.role}</p>
			</div>
		</div>
	);
};

export default UserPanel;
