import { authReducer } from './reducers/authReducers'
import thunk from 'redux-thunk';
import { combineReducers,applyMiddleware,compose,createStore } from 'redux';
import { productReducer } from './reducers/productReducer';
import { SettingReducer } from './reducers/settingReducer';
import { orderReducer } from './reducers/orderReducers';
import { storeReducer } from './reducers/storeReducer';
import { reportReducer } from './reducers/reportReducers';

const appReducer = combineReducers({
    auth: authReducer,
    product:productReducer,
    settings:SettingReducer,
    orders:orderReducer,
    store:storeReducer,
    reports:reportReducer
})

const reducer = (state, action) => {
    if (action.type === 'RESET_USER') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [ thunk ];

const store = createStore(reducer, composeEnhancers(
    applyMiddleware(...middleware)
));

export default store;