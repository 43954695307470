import React from 'react'
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { Controller } from "react-hook-form";
import Select from 'react-select';

const SelectInput = ({ fieldName, placeholder, control,fieldLabel, error, children, max,isMultiple,selectvalue, onInputChange, onChangeValue, options, disabled = false }) => {
    return (
        <>
            <FormGroup>
                <Label
                    htmlFor="recipient-name"
                    className="col-form-label"
                >
                    {fieldLabel}
                </Label>
                <Controller
                    name={fieldName}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) =>
                        <Select
                            onInputChange={onInputChange}
                            isDisabled={disabled}
                            placeholder={placeholder || null}
                            value={selectvalue ? selectvalue : value}
                            onBlur={onBlur}
                            aria-invalid={error ? "true" : "false"}
                            maxLength={max}
                            isMulti={isMultiple}
                            options={options}
                            onChange={onChangeValue ? onChangeValue : onChange}
                        />
                    }
                />
                {error && <p role="alert" style={{ color: "red", display: "flex", flexDirection: "start", paddingLeft: "10px" ,fontSize:"12px"}}>{error?.message}</p>}
            </FormGroup>
        </>
    )
}

export default SelectInput