import {
  CREATE_Notification_FAIL,
  CREATE_Notification_SUCCESS,
  CREATE_PROFILE_FAIL,
  CREATE_PROFILE_SUCCESS,
  CREATE_SLIDER_FAIL,
  CREATE_SLIDER_SUCCESS,
  DEL_Notification_FAIL,
  DEL_Notification_SUCCESS,
  DEL_SLIDER_FAIL,
  DEL_SLIDER_SUCCESS,
  DELIVERY_CHARGE_FAILED,
  DELIVERY_CHARGE_SUCCESS,
  DELIVERY_CHARGE_UPDATED,
  GET_CUSTOMER_LIST_FAIL,
  GET_CUTOMER_LIST_SUCCESS,
  GET_Notification_FAIL,
  GET_Notification_SUCCESS,
  GET_PROFILE_FAIL,
  GET_PROFILE_SUCCESS,
  GET_SLIDER_FAIL,
  GET_SLIDER_SUCCESS,
  UPDATE_Notification_FAIL,
  UPDATE_Notification_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_SLIDER_FAIL,
  UPDATE_SLIDER_SUCCESS,
  LOADING,
  USER_MANAGEMENT_GET_LIST,
  USER_MANAGEMENT_GET_LIST_FAILED,
  USER_MANAGEMENT_DELETE,
  USER_MANAGEMENT_DELETE_FAILED,
  USER_MANAGEMENT_STATUS_UPDATED,
  GET_POST_CODES,
  DELETE_POST_CODE,
  ADD_POST_CODE,
  ADD_POST_CODE_ERR,
  SEARCH_MASTER_SUCCESS,
  DELETE_POST_CODE_FAIL,
} from "../constants/settingConstants";
import { RESET_ERROR } from "../constants/userConstants";

export const SettingReducer = (state = { error: null }, action) => {
  switch (action.type) {
    case RESET_ERROR:
      return {
        ...state,
        error: null,
        profilecreated: null,
        profileupdated: null,
        notificationdeleted: null,
        notificationcreated: null,
        Notificationupdated: null,
        sliderlistcreated: null,
        sliderdeleted: null,
        sliderupdated: null,
        delivery_data_updated: null,
        usermanagementdeleted: null,
        post_code_data: null,
        delete_post_code: null,
        post_code_added: null,
        post_code_add_err: null,
        post_code_del_err:null,
        master_post_code_search: null,
      };
    case CREATE_PROFILE_SUCCESS:
      return {
        ...state,
        profilelist: action.payload,
        profilecreated: true,
      };

    case CREATE_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        profilelist: action.payload,
      };

    case GET_PROFILE_FAIL:
      return {
        ...state,
        errot: action.payload,
      };

    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profilelist: state?.profilelist,
        profileupdated: true,
      };

    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    //*****************************NOTIFICATION***************************** */

    case GET_Notification_SUCCESS:
      return {
        ...state,
        NotificationList: action.payload,
      };

    case GET_Notification_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case CREATE_Notification_SUCCESS:
      return {
        ...state,
        NotificationList: state.NotificationList
          ? [...state.NotificationList, action.payload]
          : [action.payload],
        notificationcreated: true,
      };

    case CREATE_Notification_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case DEL_Notification_SUCCESS:
      return {
        ...state,
        NotificationList: state?.NotificationList.filter(
          (notif) => notif._id !== action.payload
        ),
        notificationdeleted: true,
      };
    case DEL_Notification_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case UPDATE_Notification_SUCCESS:
      return {
        ...state,
        NotificationList: state?.NotificationList.map((notif) =>
          notif._id === action.payload._id ? action.payload : notif
        ),
        Notificationupdated: true,
      };

    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case UPDATE_Notification_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    /**********************SLIDER******************************************** */

    case GET_SLIDER_SUCCESS:
      return {
        ...state,
        getsliderlist: action.payload,
      };
    case GET_SLIDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case CREATE_SLIDER_SUCCESS:
      return {
        ...state,
        getsliderlist: state.getsliderlist
          ? [...state.getsliderlist, action.payload]
          : [action.payload],
        sliderlistcreated: true,
      };
    case CREATE_SLIDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DEL_SLIDER_SUCCESS:
      return {
        ...state,
        getsliderlist: state?.getsliderlist.filter(
          (notif) => notif._id !== action.payload
        ),
        sliderdeleted: true,
      };
    case DEL_SLIDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_SLIDER_SUCCESS:
      return {
        ...state,
        getsliderlist: state?.getsliderlist.map((slider) =>
          slider._id === action.payload._id ? action.payload : slider
        ),
        sliderupdated: true,
      };

    case UPDATE_SLIDER_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    /**************************CUSTOMER**********************************/

    case GET_CUTOMER_LIST_SUCCESS:
      return {
        ...state,
        CustomerListdata: action.payload?.data,
      };

    case GET_CUSTOMER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    //******************************USER_MANAGEMENT********************************** */
    case USER_MANAGEMENT_GET_LIST:
      return {
        ...state,
        userManageList: action.payload,
      };

    case USER_MANAGEMENT_GET_LIST_FAILED:
      return {
        ...state,
        error: action.payload,
      };

    case USER_MANAGEMENT_DELETE:
      return {
        ...state,
        usemanagedeleted: true,
      };

    case USER_MANAGEMENT_DELETE_FAILED:
      return {
        ...state,
        usermanagementdeleted: false,
      };

    case USER_MANAGEMENT_STATUS_UPDATED:
      return {
        ...state,
      };

    case GET_POST_CODES:
      return {
        ...state,
        post_code_data: action.payload,
      };

    case DELETE_POST_CODE:
      return {
        ...state,
        delete_post_code: true,
      };

    case ADD_POST_CODE:
      return {
        ...state,
        post_code_added: action.payload,
      };

    case DELETE_POST_CODE_FAIL:
      return {
        ...state,
        post_code_del_err: action.payload,
      };

    case ADD_POST_CODE_ERR:
      return {
        ...state,
        post_code_add_err: action.payload,
      };

    case SEARCH_MASTER_SUCCESS:
      return {
        ...state,
        master_post_code_search: action.payload,
      };

    //******************************DELIVERY_CHARGE********************************** */

    case DELIVERY_CHARGE_SUCCESS:
      return {
        ...state,
        delivery_data: action.payload,
      };

    case DELIVERY_CHARGE_FAILED:
      return {
        ...state,
        delivery_data_updated: false,
        delivery_error: action.payload,
      };

    case DELIVERY_CHARGE_UPDATED:
      return {
        ...state,
        delivery_data_updated: true,
        delivery_data: action.payload,
      };

    default:
      return state;
  }
};
