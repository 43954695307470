
import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import AddStore from "./AddStore";
import EditStore from "./EditStore";
import { useDispatch, useSelector } from "react-redux";
import { DeleteStore, GetStore, UpdateStoreStatus } from "../../redux/actions/storeActions";
// import DataTables from "../../common/DataTables";
import { toast } from 'react-toastify';
import { RESET_ERROR } from "../../redux/constants/userConstants";
import DataTable from "react-data-table-component";
import SearchHeader from "../common/header_components/searchHeader";
import Switch from "../common/switch/Switch";
import Reactotron from "reactotron-react-js"



const StoreManage = () => {
    const dispatch = useDispatch()
    const [datas, setDatas] = useState([])
    const { getstorelist, storedelsuccess, updatedstore, newstore, error } = useSelector(state => state.store)
    const [open, setOpen] = useState(false);
    const [openedit, setOpenEdit] = useState(false);
    const [activeData, setActiveData] = useState(null)
    const [title, setTitle] = useState('')
    const [filterText, setFilterText] = useState('');


    const customToast = "delCat";
    const customCToast = "delCatC";
    const customUToast = "delCatU";

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };


    const handleDelete = (id) => {
        if (window.confirm("Are you sure you wish to delete this item?")) {
            dispatch(DeleteStore(id))
        }


    }


    const handleEdit = (data) => {
        setTitle("Edit Store");
        setActiveData(data)
        setOpen(true)

    }

    const onOpenModal = () => {
        setTitle('Add Store')
        setActiveData(null)
        setOpen(true);
    };

    const onCloseModal = () => {
        setOpen(false);
    };
    const onOpenEditModal = () => {
        setOpenEdit(true);
    };

    const onCloseEditModal = () => {
        setOpenEdit(false);
    };
    useEffect(() => {
        dispatch(GetStore())
    }, [])

    useEffect(() => {
        if (getstorelist) {
            setDatas(getstorelist)
        }
    }, [getstorelist])

    useEffect(() => {
        if (storedelsuccess) {
            toast.success("Store Deleted successfully", { toastId: customToast });
            dispatch({
                type: RESET_ERROR
            })
        }

    }, [storedelsuccess])


    useEffect(() => {
        if (newstore) {
            toast.success("Store Created successfully", { toastId: customCToast });
            dispatch({
                type: RESET_ERROR
            })
            setActiveData(null);
            setOpen(false);
            setTitle('')
        }

    }, [newstore])


    useEffect(() => {
        if (updatedstore) {
            toast.success("Store Updated successfully", { toastId: customUToast });
            dispatch({
                type: RESET_ERROR
            })
            setActiveData(null);
            setOpen(false);
            setTitle('')

        }

    }, [updatedstore])


    useEffect(() => {
        if (error) {
            toast.error(`${error.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [error])

    const handleToggleStatus = (storeId, currentStatus) => {
        const updatedData = {
          store_id: storeId,
          status: currentStatus === "active" ? "inactive" : "active"
        };
      
        dispatch(UpdateStoreStatus(updatedData))
          .then(() => {
            toast.success(`Store status updated successfully`);
          })
          .catch((error) => {
            toast.error(`Failed to update status: ${error.message}`);
          });
      };

    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Store Name")}</b>,
            header: <b>{Capitalize("Store Name")}</b>,
            selector: row => row?.storename,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Email Address")}</b>,
            header: <b>{Capitalize("Email Address")}</b>,
            selector: row => row?.storeemail,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Address")}</b>,
            header: <b>{Capitalize("Address")}</b>,
            selector: row => row?.address?.address,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Contact No. (+44)")}</b>,
            header: <b>{Capitalize("Contact No. (+44)")}</b>,
            selector: row => row?.primary_contact ? row?.primary_contact : "-",
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Sales Contact No. (+44)")}</b>,
            header: <b>{Capitalize("Sales Contact No. (+44)")}</b>,
            selector: row => row?.sales_mobile ? row?.sales_mobile : "-",
            style: {
                textAlign: "center",
            },
        },
        // {
        //     name: <b>{Capitalize("status")}</b>,
        //     header: <b>{Capitalize("status")}</b>,
        //     selector: row => row?.status,
        //     style: {
        //         textAlign: "center",
        //     },
        // },
        {
            name: <b>{Capitalize("Status")}</b>,
            header: <b>{Capitalize("Status")}</b>,
            cell: row => (
                <Switch
                    isOn={row.status === "active"}
                    handleToggle={() => handleToggleStatus(row._id, row.status)}
                    id={`switch-${row._id}`}
                />
            ),
            style: { textAlign: "center" }
        },
        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    <span onClick={() => handleDelete(row._id)} >

                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                    <span onClick={() => handleEdit(row)}>
                        <i
                            className="fa fa-pencil"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                </>
            )
        }
    ];


    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return (
            <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}>
                <SearchHeader onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
            </div>
        );
    }, [filterText]);

    useEffect(() => {
        // if(filterText){
        let datas = getstorelist?.filter(sto => sto?.storename?.toLowerCase().includes(filterText?.toLowerCase()) || sto?.storeemail?.toLowerCase().includes(filterText?.toLowerCase()) || sto?.primary_contact?.toLowerCase().includes(filterText?.toLowerCase()) || sto?.sales_mobile?.toLowerCase().includes(filterText?.toLowerCase()))
        // console.log({ datas })
        setDatas(datas)
        // }
    }, [filterText])

    return (
        <Fragment>
            <Breadcrumb title="Store List" parent="Products" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Store Details</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="btn-popup pull-right">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={onOpenModal}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"

                                    >
                                        Add Store

                                    </Button>

                                </div>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    <DataTable
                                        pagination columns={columns}
                                        data={datas}
                                        fixedHeader
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                    />

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <AddStore
                openModal={open}
                onCloseModal={() => {
                    setActiveData(null)
                    setOpen(false)
                }}
                data={activeData}
                setActiveData={setActiveData}
                title={title}
            />


        </Fragment>
    )
}

export default StoreManage