import React, { memo, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, mixed } from "yup"
import * as yup from 'yup'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../../common/CustomInput';
import ImagesInput from '../../common/ImagesInput';
import { useState } from 'react';
import SelectInput from '../../common/SelectInput';
import { getValue } from '@testing-library/user-event/dist/utils';
import customAxios from '../../../customAxios';
import { toast } from 'react-toastify';

const AddMarkettingModal = ({ openModal, onCloseModal, data, setActiveData, title, refetch, product, category, item }) => {

    // console.log({ category }, 'ACTIVE ITEM DATA IN MODAL')

    const [type, setType] = useState([{ value: 'category', label: 'Category' }, { value: 'product', label: 'Product' }]);
    const [myType, setMyType] = useState(null);
    const [loading, setLoading] = useState(false);



    // const product = {
    //     link: yup.string().required('Required'),
    //     title:yup.string().required('Required'),
    //     type:yup.object().required('Required'),
    //     product_id:yup.object().required('Reuired')
    // }



    // const category = {
    //     link: yup.string().required('Required'),
    //     title:yup.string().required('Required'),
    //     type:yup.object().required('Required'),
    //     category_id:yup.object().required('Reuired')
    // }



    const number = /^[0-9]+/
    const schema = yup.object({
        // link: yup.string().required('Required'),
        // title: yup.string().required('Required'),
        type: yup.object().required('Required'),
        display_order: yup.string().matches(/^-?\d*\.?\d+$/, { message: "Please enter valid number." }).required('Required'),
        // image: yup.object().nullable().required('Required')
    })

    const { register, handleSubmit, getValues, setFieldValue, control, formState: { errors }, reset, setValue, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {

        }
    });





    const clearclose = () => {
        onCloseModal()
        reset()
    }






    const onChangeType = (value) => {
        setValue('type', value)
        setError('type', '')
        setMyType(value)
    }



    const Submit = async (data) => {

        if ((data?.type?.value === 'product' && data?.product_id === undefined)) {
            toast.warn('Product id is Required');
            return false;
        } else if ((data?.type?.value === 'category' && data?.category_id === undefined)) {
            toast.warn('Category id is Required')
            return false;
        }

        const CREATE_URL = 'admin/marketing';
        const EDIT_URL = 'admin/marketing/edit'

        const formData = new FormData();
        // formData.append('link', data?.link);
        formData.append('display_order', data?.display_order);
        if (data?.image) {
            formData.append('image', data?.image);
        }

        if (item) {
            formData.append('id', item?._id);
            formData.append('status', item?.status);
        }
        formData.append('type', data?.type?.value);

        if (data?.type?.value === 'product') {
            formData.append('product_id', data?.product_id?.value);
        } else {
            formData.append('category_id', data?.category_id?.value);
        }
        try {
            setLoading(true)
            await customAxios.post(item ? EDIT_URL : CREATE_URL, formData)
            clearclose()
            refetch()
            setMyType(null)
            toast.success('Marketing added succesfully')

        } catch (err) {
            toast.error(err.message)
        } finally {
            setLoading(false);
        }
    }



    useEffect(() => {

        if (item) {
            const resp = type?.find((res) => res?.value === item?.type);
            // setValue('link', item?.link);
            // setValue('title', item?.title);
            setValue('display_order', item?.display_order);
            setMyType(resp)
            setValue('type', { ...resp })
            if (item?.type === 'product') {
                const find = product?.find((res) => res?.value === item?.product_id);
                setValue('product_id', find)
            }
            if (item?.type === 'category') {
                const find = category?.find((res) => res?.value === item?.category_id);
                // console.log({ find }, 'CATEGORY GOT')
                setValue('category_id', find)
            }
        }
    }, [item])




    return (
        <Modal isOpen={openModal} toggle={clearclose} size="md">
            <ModalHeader toggle={clearclose}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    {title}
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    {/* <CustomInput
                        fieldLabel='Title :'
                        control={control}
                        error={errors.title}
                        fieldName='title'
                    /> */}
                    <SelectInput
                        selectvalue={myType}
                        onChangeValue={onChangeType}
                        fieldLabel='Type'
                        control={control}
                        error={errors.type}
                        fieldName='type'
                        options={type}
                    />
                    {/* <CustomInput
                        fieldLabel='Link:'
                        control={control}
                        error={errors.link}
                        fieldName='link'
                    /> */}

                    {myType && myType?.value === 'product' &&
                        <SelectInput
                            fieldLabel='Product'
                            control={control}
                            error={errors.product_id}
                            fieldName='product_id'
                            options={product}
                        />}
                    {myType && myType?.value === 'category' &&
                        <SelectInput

                            fieldLabel='Category'
                            control={control}
                            error={errors.category_id}
                            fieldName='category_id'
                            options={category}
                        />}


                    {item ? <div>
                        <img src={item?.image} alt={"images"} style={{ width: "80px", height: "80px" }}></img>
                    </div> : ""}
                    <ImagesInput
                        fieldLabel='Image : 850px * 300px'
                        control={control}
                        error={errors.image}
                        fieldName='image'
                        type='file'
                        onChangeValue={(file) => {
                            setValue("image", file)
                            setError("image", '')
                        }}
                    />

                    <CustomInput
                        fieldLabel='Display Order :'
                        control={control}
                        error={errors.display_order}
                        fieldName='display_order'
                    />

                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    disabled={loading ? true : false}
                    onClick={handleSubmit(Submit)}
                >
                    {loading ? "Saving..." : "Save"}
                </Button>

            </ModalFooter>
        </Modal>
    )
}

export default memo(AddMarkettingModal)