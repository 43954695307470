import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Toast,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInput from "../../common/CustomInput";
import customAxios from "../../../customAxios";
import { toast } from "react-toastify";
import SelectInput from "../../common/SelectInput";

const ChangePrice = ({ openModal, onCloseModal, fetchProducts }) => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState({
    value: "percentage",
    label: "Percentage",
  });

  console.log(type,"type")

  const schema = yup.object({
    value: yup
    .number()
    .typeError("Price value must be a number")
    .required("Price value is required")

    // role: yup.object().nullable()
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      value: "",
      type,
    },
  });

  const clearclose = () => {
    onCloseModal();
    reset();
  };


  const createPrice = async (datas) => {
    console.log(datas,"dadadadadad")
    try {
      setLoading(true);
      let response;

      const formData = new FormData();

      formData.append("type", type?.value);
      formData.append("value", datas?.value);
        
       
      response = await customAxios.post(`admin/pricechange`,
        formData
      );

      toast.success(response?.data?.message);
      reset({
        value: "",
      });

      // Call fetchProducts to refresh the product list
      fetchProducts();

      onCloseModal();
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={openModal} toggle={clearclose} size="md">
      <ModalHeader toggle={clearclose}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Change Price
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form>
       
            <Col>
              <SelectInput
                fieldLabel="Price Type"
                selectvalue={type}
                control={control}
                error={errors.paymentStatus}
                fieldName="type"
                onChangeValue={(item) => {
                  setType(item);
                }}
                options={[
                  {
                    value: "percentage",
                    label: "Percentage",
                  },
                  { value: "other", label: "Other" },
                ]}
              />
            </Col>

          <Col>
            <CustomInput
              fieldLabel="Value"
              max={10}
              control={control}
              error={errors.value}
              fieldName="value"
              type={'number'}
            />
          </Col>
          {/* <span style={{ color: "red" }}>Image resolution should be 768px * 1000px</span> */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={loading}
          type="button"
          color="primary"
          onClick={handleSubmit(createPrice)}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
        <Button
          type="button"
          color="secondary"
          onClick={() => {
            onCloseModal();
            reset();
          }}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChangePrice;
