import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { RESET_ERROR } from "../../redux/constants/userConstants";
import DataTable from "react-data-table-component";
import SearchHeader from "../common/header_components/searchHeader";
import { getReportList, filterReport } from "../../redux/actions/reportActions";
import moment from "moment";
import CustomInput from "../common/CustomInputPast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CSVLink } from "react-csv";

const Reports = () => {
    const dispatch = useDispatch();

    const { reportList, error } = useSelector(state => state.reports);
    const { loading } = useSelector(state => state.auth);
    const [datas, setDatas] = useState([]);
    const [filterText, setFilterText] = useState('');

    const csvHeaders = [
        { label: "Order Date", key: "orderDate" },
        { label: "Product Name", key: "product_name" },
        { label: "Total Quantity", key: "total_qty" },
        { label: "Order Status", key: "orderStatus" },
    ];

    const csvData = React.useMemo(() => {
        if (!Array.isArray(datas)) return []; // Ensure datas is an array

        return datas.map((row) => ({
            orderDate: row?.orderDate,
            product_name: row?.product_name,
            total_qty: row?.total_qty,
            orderStatus: row?.orderStatus
        }));
    }, [datas]);

    console.log(reportList, "list");

    // Form validation schema
    const schema = yup.object({
        date: yup.string().required("Date is required"),
    });

    // Form handling
    const {
        handleSubmit,
        control,
        reset, // For resetting the form
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {},
    });

    // Define filterReports before its usage
    const filterReports = (data) => {
        console.log(data, "dadadadad")
        dispatch(filterReport(data));
    };

    // Define resetFun before its usage
    const resetFun = () => {
        reset(); // Reset the form fields
        setFilterText(''); // Reset the filter text
        window.location.reload();
        dispatch(getReportList()); // Fetch the complete report list
    };

    useEffect(() => {
        dispatch(getReportList());
    }, [dispatch]);

    useEffect(() => {
        if (reportList) {
            setDatas(reportList);
        }
    }, [reportList]);

    useEffect(() => {
        if (error) {
            const errorMessage = error.message || "An unexpected error occurred";
            toast.warning(errorMessage);
            dispatch({
                type: RESET_ERROR
            });
        }
    }, [error, dispatch]);

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const columns = [
        {
            name: <b>{Capitalize("Order Date")}</b>,
            header: <b>{Capitalize("Order Date")}</b>,
            selector: (row) => row?.orderDate ? new Date(row.orderDate) : new Date(0), // Raw Date object for sorting
            sortable: true,
            cell: (row) =>
                row?.orderDate
                    ? `${moment(row.orderDate).format("DD-MM-YYYY")}`
                    : "-", // Formatted display
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Product Name")}</b>,
            header: <b>{Capitalize("Product Name")}</b>,
            selector: row => row?.product_name,
            sortable: true,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Total Quantity")}</b>,
            header: <b>{Capitalize("Total Quantity")}</b>,
            sortable: true,
            selector: row => row?.total_qty ? row?.total_qty : "-",
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Order Status")}</b>,
            header: <b>{Capitalize("Order Status")}</b>,
            selector: row => row?.orderStatus ? row?.orderStatus : "-",
            sortable: true,
            style: {
                textAlign: "center",
            },
        },
    ];

    useEffect(() => {
        if (filterText) {
            const filteredData = reportList?.filter(un =>
                un?.product_name?.toLowerCase().includes(filterText.toLowerCase()) ||
                un?.total_qty?.toString().toLowerCase().includes(filterText.toLowerCase()) ||
                un?.orderStatus?.toLowerCase().includes(filterText.toLowerCase())
            );
            setDatas(filteredData);
        } else {
            setDatas(reportList);
        }
    }, [filterText, reportList]);

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setFilterText('');
            }
        };

        return (
            <div style={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
                <Row style={{ marginLeft: -35 }}>
                    <Col>
                        <CustomInput
                            fieldLabel="Date Filter"
                            control={control}
                            error={errors.date}
                            fieldName="date"
                            type={"date"}
                        />
                    </Col>
                    <Col style={{ paddingTop: 35 }}>
                        <Button
                            type="button"
                            color="primary"
                            onClick={handleSubmit(filterReports)}
                        >
                            Submit
                        </Button>
                    </Col>
                    <Col style={{ paddingTop: 35 }}>
                        <Button type="button" color="secondary" onClick={resetFun}>
                            Reset
                        </Button>
                    </Col>
                </Row>
                <div style={{ display: "flex", alignItems: "start", gap: 10 }}>
                    <SearchHeader onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
                    <CSVLink
                        data={csvData}
                        headers={csvHeaders}
                        filename={`order-reports-${moment().format("YYYY-MM-DD")}.csv`}
                        className="btn btn-success"
                        target="_blank"
                    >
                        Export CSV
                    </CSVLink>
                </div>
            </div>
        );
    }, [filterText, control, errors, handleSubmit, filterReports, resetFun]);

    return (
        <Fragment>
            <Breadcrumb title="Reports" parent="Products" />
            <Container fluid>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Reports</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    {reportList && (
                                        <DataTable
                                            pagination
                                            columns={columns}
                                            data={datas}
                                            fixedHeader
                                            subHeader
                                            subHeaderComponent={subHeaderComponentMemo}
                                            progressPending={loading}
                                        />
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

export default Reports;